
import { defineComponent } from 'vue'
import user from '@/utils/user'
import store from '@/store'
import componentTabs from '@/components/tabs.vue'
import { dateFormat } from '../utils/util'
import dialogRecharge from '@/components/seat/dialog-recharge.vue'
import screenfull from 'screenfull/dist/screenfull'

export default defineComponent({
    data() {
        return {
            userIcons: '',
            $store: store,
            isDownload: false,
            fileList: [] as any,
            typeArr: [
                '发薪单列表',
                '发薪明细',
                '对账单',
                '工资单',
                '领薪记录',
                '个人领薪记录',
                '归档管理',
                '合同列表',
                '批量投保明细',
                '日保列表',
                '充值记录',
                '下载中心',
                '长期保员工名单',
                '日保在保人员名单',
                '长期保在保人员名单'
            ],
            secondary: {
                pay: '发薪充值记录',
                insurance: '日日保充值记录',
                sign: '电子签充值记录',
                period: '长期保充值记录'

            } as any,
            showDialog: false,
            isOpenPaySalary: false,
            hintList: [
                '随时掌握职工动态',
                '一键启动大屏招聘',
                '实力尽显，不止于屏'
            ],
            showHint: localStorage.getItem('closeBigScreenHint') !== 'true' ?? true,
            user: user
        }
    },
    async mounted() {
        await user.waitLogin()
        if (user.isOpenPaySalary) {
            this.isOpenPaySalary = user.isOpenPaySalary
        }
    },
    created() {
        setTimeout(() => {
            this.isDownload = !!(store.state.fileList.length > 0 || this.isDownload)
        }, 1000)
    },
    watch: {
        // 监听 fileList 的变化 根据他的变化 做出修改
        '$store.state.fileList': {
            deep: true, // 深度监听设置为 true
            handler: function (newVal, oldVal) {
                console.log('数据发生变化啦') // 修改数据时，能看到输出结果
                console.log(newVal, oldVal)
                const arr = [...newVal]
                console.log(arr, 'arrarrarr')
                this.fileList = []
                this.isDownload = !!(newVal.length > 0 || this.isDownload)
                // this.fileList.push(...newVal)
                console.log(store.state.fileCloneList, 'fileCloneList')
                store.state.fileCloneList.forEach((item: any) => {
                    console.log(item, 'item')
                    if (arr.length === 0) {
                        this.fileList = []
                        return
                    }
                    arr.forEach((ite: any, index: number) => {
                        if (ite.id === item.id) {
                            this.fileList.push(ite)
                            this.fileList[index].fileName = (item.exportType - 1 === 10 ? this.secondary[`${item.remark}`] : this.typeArr[item.exportType - 1]) + '_' + dateFormat('YYYY-mm-dd HH:MM:SS', new Date(item.updateTime)) + (item?.type ? '.zip' : '.xlsx')
                            console.log(this.fileList, index, '每一次循环赋值的东西')
                        }
                    })
                })
                console.log(this.fileList, '看看其他的东西')
                this.isDownload = !!(store.state.fileList.length > 0)
            }
        },
        // isDownload
        '$store.state.isDownload': {
            deep: true, // 深度监听设置为 true
            handler: function (newVal, oldVal) {
                this.isDownload = false
                // console.log(newVal, oldVal, '监听到啦')
            }
        },
        '$store.state.isOpenRecharge': {
            handler: function (newval) {
                this.showDialog = true
            }
        }
    },
    components: {
        'component-tabs': componentTabs,
        dialogRecharge
    },
    methods: {
        onGoDownload() {
            console.log('跳转')
            store.commit('ces')
        },
        // download-zone
        exit() {
            user.exit()
        },
        goNav(index: number) {
            this.$router.go(-(store.state.titles.length - index - 1))
        },
        onIsDownload() {
            console.log(this.$store.state.fileList, '看看是什么类型')
            if (this.$store.state.fileList.length === 0 || this.$store.state.fileList.length === undefined) {
                this.$router.push({
                    path: '/download'
                })
                return
            }
            this.isDownload = !this.isDownload
        },
        // delTab (page:string, name:string) {
        //     console.log({ page, name }, 'obj')
        //     store.commit('delPage', { page, name })
        // },
        // goTabPage (path:string) {
        //     this.$router.push({
        //         path: path
        //     })
        // },
        modifyCode() {
            this.$router.push({
                path: '/code/change'
            })
        },
        handleChangeTab(ev: string) {
            this.$emit('changeTab', ev)
        },
        toBI() {
            this.$router.push({
                path: '/bigScreen'
            })
            setTimeout(() => {
                (screenfull as any).toggle(store.state.fullScreenContainer)
            }, 200)
        },
        onCloseHint() {
            this.showHint = false
            localStorage.setItem('closeBigScreenHint', 'true')
        }
    }
})
