import request from './request'

// 获取验证码
export interface GetPhotoResDto {
    img: string
    uuid: string
}
export function getPhoto(): Promise<GetPhotoResDto> {
    return request.get('/captchaImage')
}

// 登录
export interface LoginDto {
    username: string
    password: string
    code: string
    uuid: string
}
interface LoginResDto {
    token: string
}
export function login(data: LoginDto): Promise<LoginResDto> {
    return request.post('/login', data)
}

// 获取用户基本信息
export interface GetUserInfoResDto {
    accountExpireInfo: {
        accountExpireTime: string | null,
        status: number
    }
    permissions: [
        // 按钮权限
        'pay:payProject:list',
        'pay:payWorkerRecordPersonnel:remove',
        'pay:payWorkerRecordPersonnel:add',
        'pay:payWorkerProjectRecord:export'
    ]
    roles: ''[]
    isOpenEleSign: boolean // 是否开通电子签
    isGsc: boolean // 是否国寿财
    isOpenPaySalary: boolean // 是否开通发薪
    isOpenWesure: boolean // 是否开通保险
    bankInfo: {
        bankLogo: string,
        bankName: string,
        bankNo: string,
        companyId: number,
        isDistribution: string | null,
        openBank: string,
        receiptId: number,
        wechatMchId: string,
    }
    user: {
        userId: number
        userName: string
        nickName: string
        companyName: string
        email: string
        phonenumber: string
        sex: '0' | '1'
        avatar: string
        roles: [
            {
                searchValue: null
                roleId: 103
                roleName: '发薪管理员'
                roleKey: 'saas-pay-manager'
                roleSort: '4'
                dataScope: '1'
                menuCheckStrictly: false
                deptCheckStrictly: false
                status: '0'
                delFlag: null
                flag: false
                menuIds: null
                admin: false
            }
        ]
        companyId: string // B端公司ID
        admin: boolean // 是否管理员
    }
}
export function getUserInfo(): Promise<GetUserInfoResDto> {
    return request.get('/getInfo')
}

// 获取菜单
export interface UserMenuDto {
    name: string
    path: string
    hidden: boolean
    redirect?: string
    alwaysShow?: boolean
    component: string
    meta: {
        title: string
        icon: string
        noCache: boolean
        remark: string
    }
    children?: UserMenuDto[]
}
export function getMenu(): Promise<UserMenuDto[]> {
    return request.get('/getRouters')
}

// 通过code获取token
export function getTokenAsCode(data: {
    code: string
}): Promise<{ token: string }> {
    return request.post('/api/v1/weixin/auth', data)
}

// 修改密码
export function modifyPassword(data: {
    oldPassword: string
    newPassword: string
}): Promise<{ token: string }> {
    return request.post('/rest/user/profile/updatePwd', data)
}

//
export function getqueryreceiptaccountapi(data: any) {
    return request.get('/rest/company/query-receipt-account', { params: data })
}

export function getsaasCompanyinfoapi(data: any) {
    return request.get('/saas/note/saasCompany/check-period-area', { params: data })
}
